//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import config from '../../../app.config';
import Loader from "../main/Loader";
import TabLinks from "@/components/auth/TabLinks";
import PhoneForm from "@/components/auth/Login/PhoneForm";
import CodeForm from "@/components/auth/Login/CodeForm";
import Training from "@/components/trainingNew/TrainingLogin";

export default {
  name: "Login",
  components: {CodeForm, PhoneForm, TabLinks, Loader, Training},
  metaInfo() {
    return {
      title: this.title,
    }
  },
  created() {
    if (this.token) {
      this.$router.push({
        name: 'courses'
      })
    }
  },
  data: function () {
    return {
      error: '',
      agreeError: false,
      loginError: false,
      passwordError: false,
      config,
      showLoader: false,
      title: this.$i18n.t('auth.title'),
      phoneOrEmail: '',
      pin: '',
      id: '',
      code: '',
      needCheckCode: false,
      timeOut: 0,
      message: '',      
      hideTrainings: config.variables && !config.variables.showTrainingsTab,
      //hash: ''
    }
  },
  methods: {
    setShowLoader(value) {     
      this.showLoader = Boolean(value)
    },
    setPhoneOrEmail(val) {
      this.phoneOrEmail = val
    },
    setAgreeError(val) {
      this.agreeError = val
    },
    setPin(value) {
        this.pin = value
    },

    setNeedCheckCode(value) {
      this.needCheckCode = value
    },

    setError(error = '') {
      this.error = error
    },
    setLoginError(error) {
      this.loginError = error
    },
    getLoginError() {
      let workerIdRegex = /^\d{1,7}$/
      return this.loginError && !workerIdRegex.test(this.phoneOrEmail)
    },
    setPasswordError(error) {
      this.passwordError = error
    },
    getPasswordError() {
      return this.passwordError
    },
    setMessage(message = '') {
      this.message = message
    },

    cleanErrors() {
      this.error = ''
    },
    checkResponse(response, isCode) {
      if (response.data && response.data.error) {
        response.data.errorStr ? this.setError(response.data.errorStr) : this.setError(this.$t('errors.undefined_error'))
        if (!isCode) {
          this.needCheckCode = false
        }

        this.message = ''
      }

      if (response.data && response.data.result && response.data.result.need_confirmation) {
        this.needCheckCode = true
        this.message = this.$t('login.need_code') + ' ' + this.phoneOrEmail
        this.cleanErrors()
        this.loginError = false
        this.passwordError = false

        if (response.data.result.timeout) {
          this.timeOut = response.data.result.timeout * 1000
        }
      }
    },
    ...mapActions([
      'login',
      'getUserAndStudents',
      'getStudentInfo',
    ]),
    ...mapMutations([
      'setToken',
      'setAuthMethod'
    ]),
  },
  computed: {
    docUrl() {
      if (!process || !process.env || !process.env.VUE_APP_AGREE_DOCS_URL) {
        return ''
      }
      return process.env.VUE_APP_AGREE_DOCS_URL
    },
    showTests() {
      return this.trialTests ? true : false;
    },
    ...mapState([
      'token',
      'authMethod',
      'idAuthMethod',
      'phoneAuthMethod',
      'trialTests'
    ]),
    ...mapGetters([
      'isIdAuthMethod',
      'isPhoneAuthMethod',
    ])
  }
}
