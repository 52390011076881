//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/api/api"
import {mapMutations} from 'vuex'
import $ from "jquery";
import utils from "@/utils";
import SettingIcon from "@/components/icons/SettingIcon";
import SettingSelectIcon from "@/components/icons/SettingSelectIcon";
import TodayDocuments from "@/components/icons/TodaysDocumentsIcon";
import TodaySelectDocuments from "@/components/icons/TodaysDocumentsSelectIcon";
import TodayDocumentsMob from "@/components/icons/TodaysDocumentsMobIcon";
import TodaySelectDocumentsMob from "@/components/icons/TodaysDocumentsSelectMobIcon";

export default {
  name: "DropdownSelectDocuments",
  props: {
    documents: Array,
    isDocuments: Boolean,
    isPersonnelDocuments: Boolean,
    total: Number,
    setShowPreviewLoader: Function,
    setFilter: Function,
    getDocuments: Function,
    setToday: Function,
    isTodayDocument: Boolean,
    todayDocumentToggle: Function,
    filterToggle: Function,
  },
  computed: {
    selected() {
      let res = this.documents.filter(function (item) {
        return item.checked
      })
      res = res.map(function (item) {
        return item.id
      })

      return res
    },
  },
  methods: {
    showPreview(id, previewLink = null) {
      if (previewLink) {
        this.$router.push({
          name: 'document',
          params: {
            link: previewLink,
            type: this.isDocuments ? 'doc' : 'personnel'
          }
        })
      } else {
        this.setShowPreviewLoader(true)

        const response = this.isDocuments
            ? api.getDocumentsPreview(id)
            : api.getDocumentsPreview(id, 'persDoc')

        response.then(res => {
          this.setShowPreviewLoader(false)
          if (!res.data.result || !res.data.result.previewLink) {
            return
          }

          const params = {
            name: 'document',
            params: {
              link: res.data.result.previewLink,
              type: res.data.result.type || 'doc'
            }
          }

          if (this.isPersonnelDocuments) {
            params.query = {
              type: 'personnel'
            }
          }

          window.location.href = res.data.result.previewLink;
        })
      }
    },
    showGetArchiveModalSelected() {
      this.setIdDocuments(this.selected)
      $('#modalGetArchive').modal('show')
    },
    showSelected() {
      this.showPreview(this.selected)
    },
    downloadsStudentDocumentsWithSign() {
      utils.downloadStudentDocsWithSign(this.selected)
    },
    ...mapMutations([
      'setIdDocuments'
    ])
  },
  components: {
    SettingIcon,
    SettingSelectIcon,
    TodayDocuments,
    TodaySelectDocuments,
    TodayDocumentsMob,
    TodaySelectDocumentsMob
  }
}
