//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from "../../../app.config";
import NoImage from "../main/NoImage";

export default {
  name: "Material",
  components: {NoImage},
  props: {
    material: Object,
    statusClasses: Object,
    course: Object
  },
  computed: {
    materialParams() {
      return { 
        id: this.material.ID, 
        ...(this.$route.params.type ? { type: this.$route.params.type } : {}), 
        ...this.course ,
      };
    },
    link() {
      return this.material ? this.material.TYPE : ''
    },
    name() {
      return this.isLesson ? this.$i18n.t('course.lesson') : this.$i18n.t('course.test')
    },
    isLesson() {
      return this.material && (this.material.TYPE === 'lesson')
    },
    isAvailable() {
      return this.material && this.material.AVAILABLE
    },
    isPassed() {
      return this.material.PASSED
    },
    img() {
      if (config && config.siteUrl && this.material.PICTURE) {
        return this.material.PICTURE ? config.siteUrl + this.material.PICTURE.src : '';
      }

      return '';
    },
    statusClass() {
      if (!this.material) {
        return '';
      }

      if (!this.isAvailable) {
        return 'bg-dark'
      }

      if (!this.isPassed) {
        return this.isLesson ? 'bg-gray' : 'bg-warning'
      }

      return '';
    },
    statusText() {
      if (!this.material) {
        return '';
      }

      if (!this.isAvailable) {
        return this.$i18n.t('course.not_available')
      }

      if (this.isLesson) {
        return this.isPassed ? this.$i18n.t('course.viewed') : this.$i18n.t('course.not_viewed')
      } else {
        return this.isPassed ? this.$i18n.t('course.get') : this.$i18n.t('course.not_get')
      }
    }
  }
}
