import api from "../api/api";
import router from "../router";
import util from '../utils'
import notification from "@/notification/notification";

export default {
  /**
   *
   */
  getInfo() {
    this.dispatch('getWorker');
  },
  /**
   *
   * @param context
   */
  getCourses() {
    return api.courses();
  },
  /**
   *
   * @param params
   * @param context
   */
  async getCourse(context, params) {
    context.commit('setCourseError', '')
    context.commit('setCourse', {});
    context.commit('setShowCourseLoader', true);
    context.commit('setCourseError', '');
    context.commit('setCourseRestricted', '')

    if (!(params.needId || params.extNeedId || params.trainingId)){
      notification.error('Неправильные параметры курса');
      router.push('/');
      return;
    }
    const response = api.course(params);

    return response.then(res => {
      context.commit('setShowCourseLoader', false);
      if (res.data.error && res.data.error === 401) {
        this.dispatch('logout', context);
      }

      if (res.data.error && res.data.error === 403 && res.data.errorStr) {
        context.commit('setCourseRestricted', res.data.errorStr)
      } else if(res.data.error && res.data.errorStr) {
        notification.error(res.data.errorStr)
      }

      if (!res.data.result || !res.data.result.course) {
        if (res.data.error && res.data.errorStr) {
          context.commit('setCourseError', res.data.errorStr)
        } else {
          router.push({
            name: 'courses'
          })
        }

        return res;
      }

      const course = res.data.result.course;

      let lessonsIndex = 1;
      let testsIndex = 1;

      for (let group of course.GROUPS) {
        for (let material of group.MATERIALS) {
          if (material.TYPE === 'lesson' && !material.SLIDES_FOLDER) {
            continue
          }

          if (material.TYPE === 'lesson') {
            material.INDEX = lessonsIndex;
            material.GROUP = group.NAME;
            lessonsIndex++;
          } else if (material.TYPE === 'test') {
            material.INDEX = testsIndex;
            material.GROUP = group.NAME;
            testsIndex++;
          }
        }
      }

      if (util.isIterable(course.ROOT.MATERIALS)) {
        for (let material of course.ROOT.MATERIALS) {
          if (material.TYPE === 'lesson' && !material.SLIDES_FOLDER) {
            continue
          }

          if (material.TYPE === 'lesson') {
            material.INDEX = lessonsIndex;
            lessonsIndex++;
          } else if (material.TYPE === 'test') {
            material.INDEX = testsIndex;
            testsIndex++;
          }
        }
      }

      if (util.isIterable(course.FINAL_TESTS)) {
        for (let material of course.FINAL_TESTS) {
          material.INDEX = testsIndex;
          testsIndex++;
        }
      }

      if (!course.ROOT.MATERIALS) {
        course.ROOT.MATERIALS = [];
      }

      context.commit('setCourse', course);
    });
  },
  /**
   * Get history and
   * @param context
   */
  getWorker(context) {
    const response = api.worker();

    response.then(res => {
      if (res.data.error && res.data.error === 401) {
        this.dispatch('logout', context);
      }

      if (res.data.error && res.data.error === 403 && res.data.errorStr) {
        notification.error(res.data.errorStr)
      }

      if (!res.data.result || !res.data.result.worker) {
        return
      }

      const workerData = res.data.result.worker;

      const worker = workerData;

      const history = workerData.history
      const email = workerData.email

      context.commit('setWorker', worker);
      context.commit('setHistory', history);
      context.commit('setEmail', email);
    });
  },
  async addEmail(context, options) {
    return await api.addEmail(options.email, options.subscribe);
  },
  async confirmEmail(context, code) {
    return await api.confirmEmail(code);
  },
  async confirmUserEmail(context, code) {
    return await api.confirmUserEmail(code);
  },
  async confirmUserPhone(context, code) {
    return await api.confirmUserPhone(code);
  },
  async confirmDsEmail(context, code) {
    return await api.confirmDsEmail(code);
  },
  async confirmDsPhone(context, code) {
    return await api.confirmDsPhone(code);
  },
  /**
   *
   * @param context
   */
  getStats(context) {
    const response = api.getStats();

    response.then(res => {
      if (res.data.error && res.data.error === 401) {
        this.dispatch('logout', context);
      }

      if (!res.data.result || !res.data.result.statistic) {
        return
      }

      const stats = res.data.result.statistic;

      context.commit('setStatistic', stats);
    });
  },
  login(context, options){
    return api.login(options);
  },
  logout(context) {
    context.commit('setToken', '');
    context.commit('setEmptyData');

    localStorage.edu_app_token = '';
    var withoutRedirect = router.currentRoute.meta && router.currentRoute.meta.withoutRedirect === true;

    if (!withoutRedirect) router.push({
      name: 'login'
    });
  },
  async getStudentSettings(context) {
    const response = api.getStudentSettings();

    await response.then(res => {
      if (res.data.error && res.data.error === 401) {
        this.dispatch('logout', context);
      }

      if (!res.data.result || !res.data.result.settings) {
        return
      }

      const settings = res.data.result.settings;

      context.commit('setSettings', settings);
      
      if (settings.ALLOW_DIGITAL_SIGN) {
        this.dispatch('getCertificate', context);
        this.dispatch('getDsRequest', context);
      }
    });
  },
  getTest(context, testId) {
    return api.getTestQuestions(testId);
  },
  getCertificate(context) {
    context.commit('setPersonalCertificateLoading', true);
    const response = api.getCertificate()
    
    response.then(res => {
      if (res.data && res.data.result && res.data.result.ID) {
        context.commit('setPersonalCertificate', res.data.result);
      } else {
        context.commit('setPersonalCertificate', {});
      }
    }).finally(() => {
      context.commit('setPersonalCertificateLoading', false);
    })
  },
  getDsRequest(context) {
    context.commit('setPersonalCertificateLoading', true);
    const response = api.getDsRequest()

    response.then(res => {
      if (res.data && res.data.result && res.data.result.ID) {
        context.commit('setDsRequest', res.data.result);
      } else {
        context.commit('setDsRequest', {});
      }
    }).finally(() => {
      context.commit('setPersonalCertificateLoading', false);
    })
  },
  getTrialTests({state, commit}) {
    commit('setTrialTestLoading', true);
    const response = api.getTrialTests();
    response.then(data => {
      console.log("data: ", data);
      if (data.data.error && data.data.error === 401) {
         router.push({
          name: 'login',
        })
      }

      
      if (data.data.error && data.data.error === 403 && data.data.errorStr) {
        this.restrictedError = data.data.errorStr;
      } else if (data.data && data.data.error && data.data.errorStr) {
        notification.error(data.data.errorStr);
      }
      
      if (!data.data.result || !data.data.result.testsTree || !Array.isArray(data.data.result.testsTree)) {
        state.trialTests = null;
      } else {
        state.trialTests = data.data.result.testsTree[0];
      }
    }).finally(() => {
      commit('setTrialTestLoading', false);
    });
    return state.trialTests;
  },
  getPaperQuestions(context, options) {
    return api.getPaperQuestions(options.testId, options.paperId);
  },
  async getStudentInfo() {
    this.dispatch('getWorker');
    this.dispatch('getStats');
    this.dispatch('getStudentSettings');
  },
  async getUserAndStudents(context) {
    this.dispatch('getUserProfile');
    const response = api.getStudents();

    await response.then(data => {
      if (data && data.data && data.data.result && data.data.result.students) {
        const students = data.data.result.students

        context.commit('setStudents', students);

        const currentStudentId = localStorage.getItem(context.state.storageStudentIdKey)

        let student = null

        if (currentStudentId) {
          student = students.find(student => student.ID === String(currentStudentId))
        }

        if (student && student.ID) {
          context.commit('setCurrentStudent', student);
        } else {
          localStorage.removeItem(context.state.storageStudentIdKey)

          if (students.length && students[0].ID) {
            context.commit('setCurrentStudent', students[0]);
            localStorage.setItem(context.state.storageStudentIdKey, students[0].ID)
          }
        }
      }
    })
  },
  async getUserProfile(context) {
    this.dispatch('getTimezones');
    const response = api.getUserProfile();

    response.then(data => {
      if (data && data.data && data.data.result && data.data.result.profile) {
        const user = data.data.result.profile
        const profile = {
          login: user.LOGIN || '',
          name: user.FULL_NAME || '',
          email: user.EMAIL || '',
          phone: user.PERSONAL_PHONE || '',
          subscribeEmail: user.NOTIFY_BY_EMAIL || '',
          subscribeSms: user.NOTIFY_BY_SMS || '',
          timezone: user.TIME_ZONE || '',
          loginType: user.LOGIN_TYPE
        }

        context.commit('setCurrentUser', profile)
      }
    })
  },
  async getTimezones(context) {
    const response = api.getTimezones();
    response.then(result => {
      if (result && result.data && result.data.result && result.data.result.timezones) {
        context.commit("setTimezones", result.data.result.timezones)
      }
    });
  },
  async getAuthSettings(context) {
    await api.getAuthMethod()
      .then(async result => {
        if (result && result.data && result.data.result && result.data.result.method
          && [context.state.idAuthMethod, context.state.phoneAuthMethod].includes(result.data.result.method)
        ) {
          context.commit('setAuthMethod', result.data.result.method);
        }

        if (result && result.data && result.data.result && result.data.result.send_timeout) {
          context.commit('setAuthCodeSendTimeout', result.data.result.send_timeout);
        }
      })
  },
  async getExternalDocuments(context, filter) {
    try {
      context.commit('setExternalDocumentsLoading', true);
      const externalDocumentsResponse = (await api.getDocuments({ ...filter, fromExternalEducation: true}));
      if (externalDocumentsResponse.data.error) throw new Error(externalDocumentsResponse.data.error);
      context.commit('setExternalDocuments', externalDocumentsResponse.data.result);
    } catch(error) {
      console.error('server error response ', error);
    } finally {
      context.commit('setExternalDocumentsLoading', false);
    }
  }
}